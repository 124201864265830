import React, { useState } from "react";
import Header from "./UI/Header";
import Footer from "./UI/Footer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    whatsAppNumber: "",
    message: "",
  });

  const validateForm = () => {
    const { firstName, lastName, email, whatsAppNumber, message } = formData;
    if (!firstName) {
      toast.error("Please Enter First Name");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      whatsAppNumber: formData.whatsAppNumber,
      message: formData.message,
    };

    axios
      .post("https://api-v1.aiheadshotapi.com/v1/contactUs", data)
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);
          event.target.reset();
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            whatsAppNumber: "",
            message: "",
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Header data={{ key: "Contact Us" }} />
      <ToastContainer />

      <div className="container text-center section-padding">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <h1 className="heading">Contact our team</h1>
            <p className="opacity-50 small-heading">
              Reach out to explore how we can help elevate your user experience
              and grow your revenue.
            </p>
          </div>
          <form
            id="contactForm"
            className="col-12 col-md-6 d-flex flex-wrap align-items-center text-start"
            onSubmit={handleSubmit}
          >
            <div className="p-2 col-12 col-md-6">
              <label for="firstName" class="form-label">
                First Name
              </label>
              <input
                type="text"
                class="form-control custom-input-group"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
              />
            </div>
            <div className="p-2 col-12 col-md-6">
              <label for="lastName" class="form-label">
                Last Name
              </label>
              <input
                type="text"
                class="form-control custom-input-group"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
              />
            </div>
            <div className="p-2 col-12">
              <label for="email" class="form-label">
                Email
              </label>
              <div class="input-group">
                <span class="input-group-text">
                  <svg
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.6665 3L7.42735 6.26414C9.55117 7.4675 10.4485 7.4675 12.5723 6.26414L18.3332 3"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.67965 9.2295C1.73412 11.7842 1.76136 13.0614 2.70397 14.0077C3.64657 14.9538 4.95845 14.9868 7.5822 15.0527C9.19925 15.0933 10.8004 15.0933 12.4175 15.0527C15.0413 14.9868 16.3531 14.9538 17.2958 14.0077C18.2383 13.0614 18.2656 11.7842 18.32 9.2295C18.3376 8.40809 18.3376 7.59159 18.32 6.77017C18.2656 4.21555 18.2383 2.93825 17.2958 1.99205C16.3531 1.04586 15.0413 1.0129 12.4175 0.94698C10.8004 0.906347 9.19925 0.906347 7.58219 0.946972C4.95845 1.01289 3.64657 1.04585 2.70396 1.99205C1.76135 2.93824 1.73412 4.21555 1.67964 6.77017C1.66212 7.59159 1.66213 8.40809 1.67965 9.2295Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="email"
                  class="form-control custom-input-group"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  style={{ borderLeft: "none" }}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="p-2 col-12">
              <label for="whatsAppNumber" class="form-label">
                WhatsApp Number{" "}
              </label>
              <div class="input-group">
                <span class="input-group-text">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 11.1489 1.89906 12.2437 2.31969 13.2396C2.55215 13.7899 2.66839 14.0652 2.68278 14.2732C2.69717 14.4812 2.63595 14.7099 2.51352 15.1675L1.6665 18.3332L4.83215 17.4862C5.28974 17.3638 5.51854 17.3025 5.72652 17.3169C5.93451 17.3313 6.20971 17.4475 6.76013 17.68C7.75605 18.1006 8.85075 18.3332 9.99984 18.3332Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.15695 10.3146L7.88274 9.41316C8.18863 9.03325 8.56675 8.67958 8.59641 8.17371C8.60383 8.04595 8.514 7.4723 8.33416 6.32505C8.2635 5.87417 7.84255 5.8335 7.47793 5.8335C7.00278 5.8335 6.7652 5.8335 6.52929 5.94125C6.23111 6.07745 5.92499 6.46042 5.8578 6.78127C5.80465 7.03513 5.84415 7.21005 5.92315 7.5599C6.25869 9.04583 7.04584 10.5133 8.2664 11.7339C9.487 12.9545 10.9545 13.7417 12.4404 14.0772C12.7902 14.1562 12.9652 14.1957 13.2191 14.1425C13.5399 14.0753 13.9228 13.7692 14.0591 13.471C14.1668 13.2351 14.1668 12.9976 14.1668 12.5224C14.1668 12.1577 14.1262 11.7368 13.6752 11.6662C12.528 11.4863 11.9544 11.3965 11.8266 11.4039C11.3207 11.4336 10.9671 11.8117 10.5872 12.1176L9.68575 12.8433"
                      stroke="black"
                      stroke-width="1.5"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  class="form-control custom-input-group"
                  id="whatsAppNumber"
                  name="whatsAppNumber"
                  value={formData.whatsAppNumber}
                  onChange={handleInputChange}
                  style={{ borderLeft: "none" }}
                  placeholder="WhatsApp Number "
                />
              </div>
            </div>
            <div className="p-2 col-12">
              <label for="message" class="form-label">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                className="form-control"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="col-12 text-center pt-2">
              <button
                type="submit"
                className="custom-btn"
                style={{ borderRadius: "10px" }}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>

      <Footer data={{ key: "Contact Us" }} />
    </>
  );
};

export default ContactUs;
