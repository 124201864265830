import React, { useEffect, useState } from "react";
import Header from "./UI/Header";
import Footer from "./UI/Footer";

const APIDocs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0)
  const code = `curl --location 'https://api-v1.aiheadshotapi.com/v1/images/process' \\
  --header 'api-key: API_KEY' \\
  --header 'Content-type: application/json' \\
  --data '{
    "input_image": "",
    "prompt": "",
    "negative_prompt": "",
    "concatenate_prompt": ""
  }'`;

  const successCode = `{
    "status": "success",
    "message": "Image processed successfully!",
    "data": [
        "https://storage.googleapis.com/ai-web-app-39e5a.appspot.com/generated-images/6d258431-3c1e-4f50-b860-888c7c196320.png"
    ]
}`;

  const parameters = [
    {
      name: "input_image",
      type: "string (uri)",
      description: "The URL of the input image to use as a base.",
      example: "https://deep-image.ai/api-example.png"
    },
    {
      name: "prompt",
      type: "string",
      description: "Settings for the desired content in the image.",
      example: "Wearing a red sweater, winter season, snow, river, and big tree."
    },
    {
      name: "negative_prompt",
      type: "string",
      description: "Elements to exclude from the image.",
      example: "No yellow sweater, no summer, no dry environment, no small tree, no people other than the main subject, no bright sunlight, no autumn leaves."
    },
    {
      name: "concatenate_prompt",
      type: "string",
      description: "Refined settings to enhance image details.",
      example: "Dark red sweater"
    }
  ];
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code)
    alert('Copied!');
  }
  const tabs = [
    {
      title: 'Welcome!',
      icon: '',
      content: (
        <div className="container text-center section-padding">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <h1 className="heading">Welcome!</h1>
              <p className="opacity-50 small-heading">
                {"{Aiheadshotapi’s API documentation}"}
              </p>
            </div>
            <div className="col-12 d-flex flex-wrap align-items-center mt-5">
              <div className="col-12 col-md-6" style={{ padding: "0 5%" }}>
                <img src="/assets/img/api-docs.jpg" className="w-100 h-100" />
              </div>
              <div className="col-12 col-md-6 text-start">
                <h3 style={{ fontWeight: "bold" }}>Overview</h3>
                <p className="opacity-50">
                  Aiheadshotapi is a powerful AI-based Headshot Generator app. It allows you to different type of headshot. Here you'll find all the documentation you need to get up and running with the API integration.
                </p>
                <p className="opacity-50">
                  In this docs guide for developers, you will find a comprehensive description of all the API features.
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Quick Start',
      icon: '',
      content: (
        <div className="col-12 text-start px-2 px-md-5 mt-2 mt-md-55 mt-5">
          <h3 style={{ fontWeight: "bold" }}>Quick Start</h3>
          <p className="opacity-50">
            Rapid integration and API access.
            <br />
            We've developed a seamless transition process for the deployment of the product that will allow you to onboard without stress or delay. Our internal team will work with you to establish a specific timeline for complete deployment and will support you throughout the process.
          </p>
        </div>
      ),
    },
    // {
    //   title: 'Get your API keys',
    //   icon: '',
    //   content: (
    //     <div className="col-12 text-start px-2 px-md-5 mt-2 mt-md-55 mt-5">
    //       <h3 style={{ fontWeight: "bold" }}>Get your API keys</h3>
    //       <p className="opacity-50">
    //         Your API requests are authenticated using API key. Any request that doesn't include an API key will return an error.
    //         <br />
    //         You need to replace API_KEY variable with your real API KEY from https://aiheadshotapi.com/Merchant/ApiSetting
    //         <br />
    //         You can get your API key in your profile after creating an account in https://aiheadshotapi.com/
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   title: 'Make your first request',
    //   icon: '',
    //   content: (
    //     <div className="col-12 text-start px-2 px-md-5 mt-2 mt-md-55 mt-5">
    //       <h3 style={{ fontWeight: "bold" }}>Make your first request</h3>
    //       {/* <pre id="curl-code" style={{ whiteSpace: "pre", counterReset: "lineNumber", }}>
    //         <code className=" Language  language-generic" style={{ textAlign: 'left', whiteSpace: "pre", wordSpacing: "normal", wordWrap: "normal", lineHeight: 1.5 }}>
    //           curl --location ' https://api-v1.aiheadshotapi.com/v1/images/process' \
    //           --header 'api-key: API_KEY' \
    //           --header 'Content-Type: application/json' \
    //           --data '
    //           "input_image":"",
    //           "prompt":"",
    //           "negative_prompt":"",
    //           "concatenate_prompt":""
    //           '
    //         </code>
    //       </pre> */}
    //       <div
    //         style={{
    //           backgroundColor: '#1e1e1e',
    //           padding: '20px',
    //           borderRadius: '5px',
    //           fontFamily: 'monospace',
    //           position: 'relative',
    //           margin: '20px'
    //         }}
    //       >
    //         <div
    //           style={{
    //             display: 'flex',
    //             justifyContent: 'space-between',
    //             alignItems: 'center',
    //             marginBottom: '10px'
    //           }}
    //         >
    //           <span style={{ color: '#808080' }}>bash</span>
    //           <button
    //             onClick={copyToClipboard}
    //             style={{
    //               backgroundColor: 'transparent',
    //               border: '1px solid #ffffff40',
    //               color: '#fff',
    //               padding: '5px 10px',
    //               borderRadius: '4px',
    //               cursor: 'pointer',
    //               display: 'flex',
    //               alignItems: 'center',
    //               gap: '5px'
    //             }}
    //           >
    //             <svg
    //               width="16"
    //               height="16"
    //               viewBox="0 0 24 24"
    //               fill="none"
    //               stroke="currentColor"
    //               strokeWidth="2"
    //               style={{ marginRight: '5px' }}
    //             >
    //               <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
    //               <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    //             </svg>
    //             Copy code
    //           </button>
    //         </div>
    //         <pre
    //           style={{
    //             color: '#fff',
    //             margin: 0,
    //             whiteSpace: 'pre-wrap',
    //             wordWrap: 'break-word'
    //           }}
    //         >
    //           <code>{code}</code>
    //         </pre>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: 'API methods',
      icon: '',
      content: (
        <>

          <div className="col-12 text-start px-2 px-md-5 mt-2 mt-md-5 mt-5">
            <h3 style={{ fontWeight: "bold" }}>Get your API keys</h3>
            <p style={{ fontWeight: "bold" }}>
              <span className="opacity-50">
                Your API requests are authenticated using API key. Any request that doesn't include an API key will return an error.
              </span>
              <br />
              <span className="opacity-50"> You need to replace </span>API_KEY <span className="opacity-50"> variable with your real API KEY from </span>
              <span onClick={() => {
                window.location.href = 'https://app.aiheadshotapi.com/Merchant/ApiSetting'
              }}
                style={{ cursor: 'pointer' }}
              > https://aiheadshotapi.com/Merchant/ApiSetting</span>
              <br />
              <span className="opacity-50">
                You can get your API key in your profile after creating an account in https://aiheadshotapi.com/
              </span>
            </p>
          </div>
          <div className="col-12 d-flex flex-wrap">
            <div className="col-12 col-lg-6 text-start px-2 px-md-2 mt-2 mt-md-55 mt-5">
              <h3 style={{ fontWeight: "bold" }}>Body Parameters</h3>
              <div
                style={{
                  backgroundColor: '#1e1e1e',
                  padding: '20px',
                  borderRadius: '5px',
                  fontFamily: 'monospace',
                  position: 'relative',
                  margin: '20px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <span style={{ color: '#808080' }}>bash</span>
                </div>
                <pre
                  style={{
                    color: '#fff',
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word'
                  }}
                >
                  <code>
                    {parameters.map((param, index) => (
                      <div key={param.name} className="parameter-container">
                        <div className="d-flex justify-content-between align-items-start mb-3">
                          <div>
                            <div className="d-flex align-items-center gap-2 text-light">
                              <span className="fw-medium text-light">{param.name}</span>
                              <small className="text-light opacity-50">{param.type}</small>
                            </div>
                            <p className="small mb-1 text-light">{param.description}</p>
                            {param.example && (
                              <div>
                                <small className="text-light">Example: </small>
                                <code className="small bg-light px-1 rounded text-dark">{param.example}</code>
                              </div>
                            )}
                          </div>
                        </div>
                        {index < parameters.length - 1 && <hr className="my-3" />}
                      </div>
                    ))}
                  </code>
                </pre>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="col-12 text-start px-2 px-md-2 mt-2 mt-md-55 mt-5">
                <h3 style={{ fontWeight: "bold" }}>Make your first request</h3>
                <div
                  style={{
                    backgroundColor: '#1e1e1e',
                    padding: '20px',
                    borderRadius: '5px',
                    fontFamily: 'monospace',
                    position: 'relative',
                    margin: '20px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '10px'
                    }}
                  >
                    <span style={{ color: '#808080' }}>bash</span>
                    <button
                      onClick={copyToClipboard}
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid #ffffff40',
                        color: '#fff',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        style={{ marginRight: '5px' }}
                      >
                        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                      </svg>
                      Copy code
                    </button>
                  </div>
                  <pre
                    style={{
                      color: '#fff',
                      margin: 0,
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }}
                  >
                    <code>{code}</code>
                  </pre>
                </div>
              </div>
              <div className="col-12 text-start px-2 px-md-2 mt-2 mt-md-55 mt-5">
                <h3 style={{ fontWeight: "bold" }}>Response</h3>
                <div
                  style={{
                    backgroundColor: '#1e1e1e',
                    padding: '20px',
                    borderRadius: '5px',
                    fontFamily: 'monospace',
                    position: 'relative',
                    margin: '20px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '10px'
                    }}
                  >
                    <span style={{ color: '#808080' }}>bash</span>
                  </div>
                  <pre
                    style={{
                      color: '#fff',
                      margin: 0,
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }}
                  >
                    <code>{successCode}</code>
                  </pre>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 text-start px-2 px-md-5 mt-2 mt-md-55 mt-5">
            <h3 style={{ fontWeight: "bold" }}>API methods</h3>
            <p className="opacity-50">
              The API address is ' https://aiheadshotapi.com/v1/images/
              <br />
              There are two methods for the scheduling image processing and one for retrieving the results. With "process" method, image can be processed and if the result is available, the url to the result will be returned.
            </p>
          </div>
        </>
      ),
    },
    {
      title: 'Image Generation Guidelines',
      icon: '',
      content: (
        <div className="col-12 text-start px-2 px-md-5 mt-2 mt-md-52 px-md-5 mt-2 mt-md-5">
          <h3 style={{ fontWeight: "bold" }}>Image Generation Guidelines</h3>
          <ul className="px-4 my-3">
            <li className="p-1"><b>Image Input Format:</b> <span className="opacity-50">The API requires an image URL as input. Direct support for formats like bash24 is not available.</span></li>
            <li className="p-1"><b>Processing Time:</b> <span className="opacity-50">The ideal time to generate an image is 55-65 seconds, depending on the quality of the input image</span></li>
            <li className="p-1"><b>Input Image Recommendations:</b>
              <ul className="px-4 py-2">
                <li className="opacity-50 p-1">Use images with a single person.</li>
                <li className="opacity-50 p-1">Ensure the face is clear and unobstructed for optimal results.</li>
              </ul>
            </li>
            <li className="p-1"><b>Timeout Limit:</b> <span className="opacity-50">The API has a timeout period of 85 seconds.</span></li>
          </ul>
        </div>
      ),
    },
  ];
  const goToPrevious = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev))
  }

  const goToNext = () => {
    setCurrentIndex((prev) => (prev < tabs.length - 1 ? prev + 1 : prev))
  }
  return (
    <>
      <Header data={{ key: "API DOCS" }} />

      <div className="api-docs-box d-none d-md-block">
        <div className="p-4">
          <div className="p-4">
            <div className="d-flex flex-column flex-md-row gap-3">
              {/* Side Tabs */}
              <div className="col">
                <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                  {tabs.map((tab, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        setActiveTab(index);
                        setCurrentIndex(index);
                      }}
                      className={`nav-link text-start text-dark ${activeTab === index ? 'active tabs-btn' : ''}`}
                      role="tab"
                    >
                      <div className="d-flex align-items-center gap-2">
                        {tab.icon}
                        <span className="fw-bold">{tab.title}</span>
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              {/* Content */}
              <div className="col-md-10">
                <div className="bg-light rounded p-4">
                  <div className="content">
                    {tabs[activeTab].content}
                    <div className="navigation mt-3 d-flex col-12 col-xl-6 px-md-5 mx-auto justify-content-center">
                      {currentIndex > 0 && (
                        <div className="col-6 pe-2">
                          <div className="navigation-item">
                            <button
                              onClick={() => {
                                goToPrevious();
                                setActiveTab(currentIndex - 1);
                              }}
                              className="nav-button"
                            >
                              <span className="button-text">Previous</span>
                              <div className="link-text">{tabs[currentIndex - 1].title}</div>
                            </button>
                          </div>
                        </div>
                      )}

                      {currentIndex < tabs.length - 1 && (
                        <div className="navigation-item col-6">
                          <button
                            onClick={() => {
                              goToNext();
                              setActiveTab(currentIndex + 1);
                            }}
                            className={`nav-button next-button`}
                          >
                            <span className="button-text">Next</span>
                            <div className="link-text">{tabs[currentIndex + 1].title}</div>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-md-none pb-5">
        <div className="content">
          {tabs[currentIndex].content}
        </div>

        <div className="navigation">
          <div className="navigation-item">
            {currentIndex > 0 && (
              <button
                onClick={goToPrevious}
                className="nav-button"
              >
                <span className="button-text">Previous</span>
                <div className="link-text">{tabs[currentIndex - 1].title}</div>
              </button>
            )}
          </div>

          <div className="navigation-item">
            {currentIndex < tabs.length - 1 && (
              <button
                onClick={goToNext}
                className={`nav-button next-button mt-3`}
              >
                <span className="button-text">Next</span>
                <div className="link-text">{tabs[currentIndex + 1].title}</div>
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer data={{ key: "API Docs" }} />
    </>
  );
};

export default APIDocs;
