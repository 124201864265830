import React, { useEffect, useState } from "react";
import Header from "./UI/Header";
import Footer from "./UI/Footer";
import axios from "axios";

const Pricing = () => {
  const customRedirect = () => {
    window.location.href = "https://app.aiheadshotapi.com/";
  };
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(); // Default selected plan

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api-v1.aiheadshotapi.com/v1/plans/user_plans",
    };

    axios
      .request(config)
      .then((response) => {
        setPlans(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedPlan(response.data.data[0].credits);
        }
      })
      .catch((error) => {
        // Handle error
      });
  }, []);
  return (
    <>
      <Header data={{ key: "Pricing" }} />

      <div className="container text-center section-padding">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <img src="/assets/img/Design.png" alt="pricing" />
            <h1 className="heading mt-4">Our Pricing</h1>
            <p className="opacity-50 small-heading">
              Save hundreds compared to a photo shoot. <br /> Customize your AI
              professional headshot with manual edits or get.
            </p>
          </div>
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <div className="custom-card-design">
              <h4>Subscription Plans</h4>
              <img
                src="/assets/img/Divider.png"
                alt="Divider"
                className="w-100"
              />
              <ul className="list-unstyled mt-3">
                {plans.map((item, index) => {
                  return (
                    <li
                      className="d-flex justify-content-between align-items-center py-1"
                      key={index}
                    >
                      <div className="col-4 text-start ps-3">
                        <input
                          type="radio"
                          name="plan"
                          defaultChecked
                          style={{ backgroundColor: 'aqua' }}
                          className="me-2"
                        />
                        <p
                          style={{ fontWeight: "600" }}
                          className="d-inline-block m-0"
                        >
                          {item.credits} Credits
                        </p>
                      </div>
                      {/* <div className="col-4">
                                                <span className="price">₹0.07 / image</span>
                                            </div> */}
                      <div
                        className="col-4 text-end pe-3" style={{ color: '#9371F0' }}
                      >
                        ₹<b style={{ fontSize: "20px" }}>{item.amount}</b>.00
                      </div>
                    </li>
                  );
                })}
              </ul>
              <button
                className="custom-btn mt-3"
                style={{ borderRadius: "10px" }}
                onClick={customRedirect}
              >
                Subscribe Now
                <span className="ps-2">
                  <svg
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M14.5 0.25C14.8507 0.25 15.1546 0.49305 15.2317 0.83518L15.7704 3.22676C16.1637 4.97278 17.5272 6.33629 19.2732 6.7296L21.6648 7.26833C22.007 7.3454 22.25 7.64929 22.25 8C22.25 8.35071 22.007 8.6546 21.6648 8.73167L19.2732 9.2704C17.5272 9.6637 16.1637 11.0272 15.7704 12.7732L15.2317 15.1648C15.1546 15.507 14.8507 15.75 14.5 15.75C14.1493 15.75 13.8454 15.507 13.7683 15.1648L13.2296 12.7732C12.8363 11.0272 11.4728 9.6637 9.7268 9.2704L7.33518 8.73167C6.99305 8.6546 6.75 8.35071 6.75 8C6.75 7.64929 6.99305 7.3454 7.33518 7.26833L9.7268 6.7296C11.4728 6.33629 12.8363 4.97278 13.2296 3.22676L13.7683 0.83518C13.8454 0.49305 14.1493 0.25 14.5 0.25Z"
                      fill="white"
                    />
                    <path
                      d="M6.5 10.25C6.85071 10.25 7.1546 10.493 7.23167 10.8352L7.61647 12.5435C7.87923 13.7099 8.79012 14.6208 9.9565 14.8835L11.6648 15.2683C12.007 15.3454 12.25 15.6493 12.25 16C12.25 16.3507 12.007 16.6546 11.6648 16.7317L9.9565 17.1165C8.79012 17.3792 7.87923 18.2901 7.61647 19.4565L7.23167 21.1648C7.1546 21.507 6.85071 21.75 6.5 21.75C6.14929 21.75 5.8454 21.507 5.76833 21.1648L5.38353 19.4565C5.12077 18.2901 4.20988 17.3792 3.04345 17.1165L1.33518 16.7317C0.99305 16.6546 0.75 16.3507 0.75 16C0.75 15.6493 0.99305 15.3454 1.33518 15.2683L3.04345 14.8835C4.20988 14.6208 5.12077 13.7099 5.38353 12.5435L5.76833 10.8352C5.8454 10.493 6.14929 10.25 6.5 10.25Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="custom-card-design h-100">
              <h4>Enterprise Plan</h4>
              <img
                src="/assets/img/Divider.png"
                alt="Divider"
                className="w-100"
              />
              <ul className="list-unstyled mt-3">
                <li className="d-flex justify-content-between align-items-center py-1">
                  <p
                    style={{ fontWeight: "600" }}
                    className="d-inline-block m-0"
                  >
                    <span className="pe-2">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                          stroke="#9371F0"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Best price guaranteed
                  </p>
                </li>
                <li className="d-flex justify-content-between align-items-center py-1">
                  <p
                    style={{ fontWeight: "600" }}
                    className="d-inline-block m-0"
                  >
                    <span className="pe-2">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                          stroke="#9371F0"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Flexible API
                  </p>
                </li>
                <li className="d-flex justify-content-between align-items-center py-1">
                  <p
                    style={{ fontWeight: "600" }}
                    className="d-inline-block m-0"
                  >
                    <span className="pe-2">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                          stroke="#9371F0"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Dedicated Support
                  </p>
                </li>
                <li className="d-flex justify-content-between align-items-center py-1">
                  <p
                    style={{ fontWeight: "600" }}
                    className="d-inline-block m-0"
                  >
                    <span className="pe-2">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.4165 9.7085C1.4165 9.7085 3.0415 9.7085 5.20817 13.5002C5.20817 13.5002 11.2302 3.5696 16.5832 1.5835"
                          stroke="#9371F0"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Custom Solutions
                  </p>
                </li>
              </ul>
              <button
                className="custom-btn mt-3"
                onClick={() => (window.location.href = "/contactus")}
                style={{ borderRadius: "10px" }}
              >
                Contact Us
                <span className="ps-2">
                  <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 6H1"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.0001 11C12.0001 11 17 7.3176 17 6C17 4.6824 12 1 12 1"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer data={{ key: "Pricing" }} />
    </>
  );
};

export default Pricing;
