import React from "react";
import Header from "./UI/Header";
import Footer from "./UI/Footer";

const PrivacyPolicy = () => {
  const privacyContent = {
    title: "Privacy Policy",
    description:
      "Our Privacy Policy explains how we collect, use, and safeguard your personal information. By using our services, you consent to these practices.",
    sections: [
      {
        title: "",
        content: (
          <>
            {"Privacy and cookies policy for "}
            <a href="https://aiheadshotapi.com/">https://aiheadshotapi.com/</a>
          </>
        ),
      },
      {
        title: "I. Personal data processing",
        content: (
          <>
            Aiheadshotapi will not share any data with any kind of third-party,
            including AI Models.
            <br />
            Personal data of visitors of the website{" "}
            <a href="https://aiheadshotapi.com/">
              https://aiheadshotapi.com/
            </a>{" "}
            and users of Aiheadshotapi application are processed in accordance
            with the requirements of the law of Poland and European Union, in
            particular in accordance with Regulation (EU) 2016/679 of the
            European Parliament and of the Council of 27 April 2016 on the
            protection of natural persons with regard to the processing of
            personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation, hereinafter
            referred to as GDPR) For the aforementioned reasoned this document
            contains information about personal data processing legal basis,
            collection, rules of use and about the rights of data subjects
            related to it.
          </>
        ),
      },
      {
        title: "II. Definitions.",
        content: (
          <>
            <p>
              <b>II.1 Personal Data - </b>
              any information relating to an identified or identifiable natural
              person ('data subject'); an identifiable natural person is one who
              can be identified, directly or indirectly, in particular by
              reference to an identifier such as a name, an identification
              number, location data, online identifier or to one or more factors
              specific to the physical, physiological, genetic, mental,
              economic, cultural or social identity of that natural person;
            </p>
            <p>
              <b>II.2 Processing - </b>
              any operation or set of operations which is performed on personal
              data or on sets of personal data, whether or not by automated
              means, such as collection, recording, organisation, structuring,
              storage, adaptation or alteration, retrieval, consultation, use,
              disclosure by transmission, dissemination or otherwise making
              available, alignment or combination, restriction, erasure or
              destruction;
            </p>
            <p>
              <b>II.3 Profiling - </b>
              any form of automated processing of personal data consisting of
              the use of personal data to evaluate certain personal aspects
              relating to a natural person, in particular to analyse or predict
              aspects concerning that natural person's performance at work,
              economic situation, health, personal preferences, interests,
              reliability, behaviour, location or movements;
            </p>
            <p>
              <b>II.4 Personal Data Set - </b>
              means an ordered set of personal data accessible according to
              specified. criteria, regardless of whether the set is centralized,
              decentralized or functionally or geographically dispersed;
            </p>
            <p>
              <b>II.5 Controller - </b>
              natural or legal person, public authority, agency or other body
              which alone or jointly with others, determines the purposes and
              means of the processing of personal data; in this case
              Aiheadshotapi.com Sp. z o.o. (hereinafter referred to as "We";
            </p>
            <p>
              <b>II.6 Processor - </b>
              natural or legal person, public authority, agency or other body
              which processes personal data on behalf of the controller;
            </p>
            <p>
              <b>II.7 Recipient - </b>
              natural or legal person, public authority, agency or another body,
              to which the personal data are disclosed, whether a third party or
              not;
            </p>
            <p>
              <b>II.8 Consent - </b>
              (of the data subject) - any freely given, specific, informed and
              unambiguous indication of the data subject's wishes by which he or
              she, by a statement or by a clear affirmative action, signifies
              agreement to the processing of personal data relating to him or
              her;
            </p>
            <p>
              <b>II.9 Personal Data Breach - </b>a breach of security leading to
              the accidental or unlawful destruction, loss, alteration,
              unauthorised disclosure of, or access to, personal data
              transmitted, stored or otherwise processed;
            </p>
            <p>
              <b>II.10 Data Concerning Health - </b>
              personal data related to the physical or mental health of a
              natural person, including the provision of health care services,
              which reveal information about his or her health status;
            </p>
            <p>
              <b>II.11 Supervisory Authority - </b>
              President of the Office for Personal Data Protection (Prezes
              Urzędu Ochrony Danych Osobowych ul. Stawki 2 00-193 Warszawa,
              kancelaria@uodo.gov.pl);
            </p>
            <p>
              <b>II.12 Aiheadshotapi -</b>
              picture quality enhancing application developed by the Controller
              available through website{" "}
              <a href="https://aiheadshotapi.com/">
                https://aiheadshotapi.com/
              </a>
            </p>
            <p>
              <b>II.13 Website - </b>
              <a href="https://aiheadshotapi.com/">
                https://aiheadshotapi.com/
              </a>
            </p>
          </>
        ),
      },
      {
        title: "III. Controllers details",
        content: (
          <>
            Controller of your personal data is Aiheadshotapi.com Sp. z o.o.
            Address: Cyfrowa st. 6/317, 71-441 Szczecin, POLAND email {""} :{" "}
            <a href="mailto:support@aiheadshotapi.com">
              support@aiheadshotapi.com
            </a>
          </>
        ),
      },
      {
        title: "IV. Privacy policy scope of application",
        content: (
          <>
            This privacy policy applies to all cases in which the Controller
            processes your personal data in connection with your visit to the
            Website{" "}
            <a href="https://aiheadshotapi.com">(https://aiheadshotapi.com)</a>{" "}
            or use of Aiheadshotapi application. This applies to cases in which
            Controller processes personal data obtained directly from you as the
            data subject. The Controller performs its information obligation
            specified in art. 13 GDPR in accordance with these provisions.
          </>
        ),
      },
      {
        title: "V. Rules of personal data processing",
        content: (
          <>
            <p>V.1 We do not designate a data protection officer.</p>
            <p>
              V.2 We may transfer your personal data to recipients in countries
              other than the country in which the data was collected. We will
              use any measures necessary to ensure that such transfers are safe
              and comply with applicable data protection regulations.
            </p>
            <p>
              V.3 We do not process your personal data in an automated manner,
              including it does not profile personal data. Controller use
              cookies, as described in Chapter VI of this privacy policy.
            </p>
            <p>
              V.4 We process your personal data on the principles and to the
              extent set out in this privacy policy.
            </p>
            <p>
              V.5 Your personal data is processed as a condition of using the
              Aiheadshotapi application.
            </p>
            <p>
              V.6 We do not process any sensitive data referred to in art. 9
              GDPR.
            </p>
            <p>
              V.7 We will not sell or rent your data to any unaffiliated third
              parties. Your data will be shared with trusted partners and
              authorized state bodies only, and only if needed, as described
              below.
            </p>
            <p>
              V.8 Your personal data may be disclosed to Controller's business
              partners, employees, service providers and contractors to the
              extent necessary to operate the Aiheadshotapi application. If
              obliged to do so, Controller will disclose your personal data to
              authorized state bodies.
            </p>
            <p>
              V.9 In order to process your payments, your data will be
              transferred to Stripe inc who is our payment operator. Provided
              data include: payment card number, CVV number, card expiry date,
              card owner, country of residence, name and surname of card owner,
              country of residence, address, email address, telephone number
              (optional). Additional data needed in case of corporate users
              include: company name, value added tax number, tax identification
              number (depending on users country of residence), company
              headquarters country of residence, company delegated address,
              email address and telephone number (optional). For more detailed
              information about Stripe inc privacy policy go to:{" "}
              <a href="https://stripe.com/en-pl/privacy">
                https://stripe.com/en-pl/privacy
              </a>
            </p>
            <p>
              V.10 Your personal data will be processed by Controller for as
              long as its needed including the purpose of processing, but no
              shorter than it results from legal regulations.
            </p>
          </>
        ),
      },
      {
        title: "VI. Cookies policy",
        content: (
          <>
            <p>
              VI.1 To a limited extent the Controller may collect your data
              (including personal data) in an automated manner using cookies
              from{" "}
              <a href="https://aiheadshotapi.com/">
                https://aiheadshotapi.com/
              </a>
              .
            </p>
            <p>
              VI.2 Cookies are small text files saved on your computer or other
              mobile device while it is using websites.
            </p>
            <p>
              VI.3 Cookies remember your preferences, which allows, among
              others, to improve the quality of services provided, improve
              search results and the accuracy of displayed information, and
              track your preferences.
            </p>
            <p>VI.4 Controller uses 2 types of cookies indicated below:</p>
            <ul className="terms-li p-0">
              <li>
                <p>
                  a) Necessary cookies - of key importance that enable visitors
                  to navigate the Website and use its functions, such as access
                  to secure areas of the Website. Without these cookies, the
                  Website may not display correctly.
                </p>
              </li>
              <li>
                <p>
                  b) Functional cookies - used to increase the friendliness of
                  the Website for users visiting it. Functional cookies of the
                  Website, which are stored on the user's device, remain active
                  only when active on the Website.
                </p>
              </li>
            </ul>
            <p>
              VI.5 Aforementioned Cookies allows using various functions
              provided on the Website or confirming that the visitor has seen
              specific content from the Website. We use cookies in various ways
              including:
            </p>
            <ul className="terms-li p-0">
              <li>
                <p>
                  <b>a) User Authentication.</b> We use cookies to remember your
                  login information allowing us to keep you logged or at least
                  shorten the login process. Therefore you don't need to log in
                  every time you visit the Website.
                </p>
              </li>
              <li>
                <p>
                  <b>b) Security reasons.</b> We use cookies to prevent any
                  illegal use of our services.
                </p>
              </li>
              <li>
                <p>
                  <b>c) Website functions.</b> We use cookies to remember your
                  preferences regarding using the Website so that you won't need
                  to reconfigure your setting each time you log in.
                </p>
              </li>
              <li>
                <p>
                  <b>d) Improving service quality.</b> We use cookies to make
                  our services better by learning to understand users'
                  expectations e.g. by tracking how users reach the Website.
                </p>
              </li>
            </ul>
            <p>
              VI.6 We may use third party cookies like google analytics or adobe
              flash.{" "}
              <b>
                {" "}
                Google analytics cookies may be disabled using following link{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                . You can disable adobe flash cookies in your browser settings.
              </b>
            </p>
            <p>
              VI.7 You consent to the storage or access of cookies by Controller
              through browser settings installed on your device.
            </p>
            <p>
              VI.8 If you want to delete existing cookies from your device, you
              can do it using the browser options. You can opt out or modify
              cookies by selecting the appropriate settings on the web browser
              you use.
            </p>
            <ul className="terms-li p-0">
              <li>
                <p>
                  <b>a) Mozilla Firefox browser:</b> in the 'Tools' menu, select
                  'Options' and the 'Privacy' tab. The browser gives the option
                  of disabling tracking altogether or deleting individual
                  cookies of individual websites; for more detailed info go to{" "}
                  <a href="https://support.mozilla.org/products/firefox/cookies">
                    https://support.mozilla.org/products/firefox/cookies
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <b>b) Microsoft Internet Explorer browser:</b> in the 'Tools'
                  menu, select 'Internet Options' and the 'Privacy' tab. A
                  special slider can be used to regulate the overall privacy
                  level and to manage the settings of individual websites by
                  using the 'Sites' button. For more detailed info go to{" "}
                  <a href="https://support.microsoft.com/en-us/products/windows?os=windows-10">
                    https://support.microsoft.com/en-us/products/windows?os=windows-10
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <b>c) Google Chrome browser:</b> in the menu hidden under
                  three horizontal dashes in the upper right corner of the
                  browser, select 'Tools' and then 'Clear browsing data...'. In
                  addition to the option of clearing cookies, there is a 'More
                  information' link that leads to a detailed description of the
                  browser's privacy function; for more detailed info go to{" "}
                  <a href="https://support.google.com/chrome/answer/95647?hl=en">
                    https://support.google.com/chrome/answer/95647?hl=en
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <b>d) Opera browser:</b> with the 'Opera' button in the upper
                  left corner open the menu and select 'Settings' in it and then
                  'Clear browsing history...'. In addition to the option of
                  deleting already set cookies, there is also a 'Manage
                  cookies...' button leading to more advanced options for
                  individual websites; for more detailed info go to{" "}
                  <a href="http://www.opera.com/help/tutorials/security/cookies/">
                    http://www.opera.com/help/tutorials/security/cookies/
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <b>e) Apple Safari browser:</b> in the 'Safari' menu, select
                  'Preferences' and the Privacy tab. You can find numerous
                  options regarding cookies in it; for more detailed info go to{" "}
                  <a href="https://support.apple.com/kb/PH21411">
                    https://support.apple.com/kb/PH21411
                  </a>
                </p>
              </li>
            </ul>
            <p>
              f) On mobile phones, tablets and other mobile devices: each phone
              model may support this function in a different way. Therefore, we
              encourage you to familiarize yourself with the privacy options in
              the documentation on the website of the manufacturer of your
              mobile device.
            </p>
          </>
        ),
      },
      {
        title: "VII. Your rights regarding processing of your personal data",
        content: (
          <>
            <p>
              <b>VII.1 The right of access to your personal data</b> Be advised
              that you have the right to access your personal data that you
              provide to Controller.
            </p>
            <p>
              To exercise this right, you must send a request in writing by
              registered mail or by email to the indicated above Controller's
              address/email.
            </p>
            <p>
              <b>
                VII.2 The right to withdraw consent for personal data
                processing.
              </b>{" "}
              Be advised that in the field of personal data that is collected
              and processed solely on the basis of your consent (art 6 §1 lit.
              a) GDPR) you have the right to withdraw consent to the processing
              of personal data. However, in this case, withdrawal of consent
              will not affect the lawfulness of processing activities carried
              out before this consent was withdrawn. To exercise this right, you
              must:
            </p>
            <p>
              A. Login to Aiheadshotapi with your username and password. B. Go
              to My Profile section. C. Click on Close account. In case of data
              necessary to use the Aiheadshotapi application, withdrawal of
              consent will result in the inability to continue using it.
            </p>
            <p>
              <b>VII.3 The right to file a complaint</b> Please be advised that
              you have the right to file a complaint regarding the processing of
              your personal data. This complaint can be filed with the
              supervisory body (President of the Office for Personal Data
              Protection) - it is recommended that it be done in writing by
              registered mail.
            </p>
            <p>
              <b>VII.4 Requirement to provide personal data</b> Providing your
              personal data is completely voluntary; however, if you decide to
              refuse to provide it, we would like to inform you that it may not
              be possible to provide our services. In particular, it will be
              impossible to use the Aiheadshotapi application.
            </p>
            <p>
              <b>VII.5 The right to erasure personal data</b> Be advised that
              you have the right to demand from Controller immediate deletion of
              your personal data, and Controller is obliged to delete personal
              data without undue delay, with the following reservation. This
              request will be implemented by closing the User's account. You
              acknowledge that by requesting the erasure of your personal data,
              your User account will be deleted. The User can close the account
              himself by selecting the option "Close account" in his profile.
            </p>
            <p>
              The right to delete personal data is excluded in a situation where
              the processing of personal data is based on a legal basis other
              than the consent of the data subject. Furthermore, the right to
              delete personal data is not applicable to the extent that data
              processing is necessary for Controller to fulfill its legal
              obligation or to establish, assert or defend claims.
            </p>
            <p>
              <b>VII.6 The right to object to personal data processing</b> You
              have the right to object to processing of your personal data if
              its processing is based on the legal basis indicated in art. 6
              ust. 1 lit. f) GDPR, except when there are valid legitimate
              grounds for processing or there are grounds for establishing,
              pursuing or defending claims. In this case, Controller's interest
              in processing data including your IP address, domain name, browser
              type, operating system type is the correct implementation of the
              cookie policy to the extent necessary on the terms set out in
              Chapter VII of this privacy policy.
            </p>
            <p>
              <b>VII.7 The right to personal data portability</b> Be advised
              that you have the right to receive your personal data that you
              have provided to Controller in a structured, commonly used
              machine-readable format. You also have the right to send this
              personal data to another controller without any obstacles on the
              part of Controller. If it is technically possible, you also have
              the right to request that Controller send the indicated personal
              data to another controller directly.
            </p>
            <p>
              <b>
                VII.8 The right to restriction of processing of personal data
              </b>{" "}
              Please be advised that you have the right to request Controller to
              restrict the processing of your personal data in the following
              cases:
              <ul className="terms-li p-0">
                <li>
                  <p>
                    a) when you question the correctness of personal data - for
                    a period allowing the Controller to check the correctness of
                    this data.
                  </p>
                </li>
                <li>
                  <p>
                    b) if the processing of data is unlawful and you object to
                    the erasure of personal data, requesting instead to restrict
                    its use.
                  </p>
                </li>
                <li>
                  <p>
                    c) when Controller no longer needs personal data for
                    processing purposes, but you need it to determine, pursue or
                    defend claims.
                  </p>
                </li>
                <li>
                  <p>
                    d) when you object to the processing of personal data -
                    until it is determined whether the legitimate grounds on the
                    part of Controller prevail over the grounds for your
                    objection.
                  </p>
                </li>
              </ul>
            </p>
            <p>
              <b>VII.9 The right to rectification</b> Be advised that you have
              the right to request from Controller an immediate correction of
              your personal data that is incorrect. You also have the right to
              request the completion of incomplete personal data, including by
              providing an additional statement. To exercise this right, you
              must send a request in writing by registered mail or by email to
              the indicated above Controller's address/email.
            </p>
          </>
        ),
      },
      {
        title: "VIII. Legal basis for the processing of personal data",
        content: (
          <>
            <p>
              According to the GDPR, your personal data is processed on the
              basis of:
              <ul className="terms-li p-0">
                <li>
                  <p>
                    a) art. 6 ust 1 lit. a) GDPR that provides for the
                    processing of personal data on the basis of the consent of a
                    natural person.
                  </p>
                </li>
                <li>
                  <p>
                    b) art. 6 ust 1 lit. b) GDPR that provides that processing
                    is necessary for the performance of a contract to which the
                    data subject is a party or in order to take steps at the
                    request of the data subject prior to entering into a
                    contract.
                  </p>
                </li>
                <li>
                  <p>
                    c) art. 6 ust 1 lit. c) GDPR providing that processing is
                    necessary for compliance with a legal obligation to which
                    the controller is subject.
                  </p>
                </li>
                <li>
                  <p>
                    d) art. 6 ust 1 lit. f) GDPR providing that processing is
                    necessary for the purposes of the legitimate interests
                    pursued by the controller or by a third party, except where
                    such interests are overridden by the interests or
                    fundamental rights and freedoms of the data subject which
                    require protection of personal data, in particular where the
                    data subject is a child.
                  </p>
                </li>
              </ul>
            </p>
          </>
        ),
      },
      {
        title: "IX. Controller's security measures",
        content: (
          <>
            <p>
              IX.1 In order to ensure the security of your data, the Controller
              uses the following security measures:
              <ul className="terms-li p-0">
                <li>
                  <p>a) access control to the IT system</p>
                </li>
                <li>
                  <p>
                    b) restricted access to Controller's devices (authorized
                    persons only)
                  </p>
                </li>
                <li>
                  <p>c) firewall</p>
                </li>
                <li>
                  <p>d) antivirus system</p>
                </li>
                <li>
                  <p>e) backing up the database and site files</p>
                </li>
              </ul>
            </p>
          </>
        ),
      },
      {
        title: "X. External links",
        content: (
          <>
            <p>
              X.1 Website may contain links or references to other websites
              independent of Controller. Please note that Controller has no
              control over these sites and this privacy policy does not cover
              them. Therefore you should consider the privacy policies of those
              third-parties carefully.
            </p>
          </>
        ),
      },
      {
        title: "XI. Final provisions",
        content: (
          <>
            <p>
              XI.1 Controller reserves the right to change the provisions of
              this privacy policy. XI.2 This privacy policy is subject to and
              will be interpreted in accordance with Polish law and all other
              mandatory provisions of law in force in the European Union.
            </p>
          </>
        ),
      },
    ],
  };

  return (
    <>
      <Header data={{ key: privacyContent.title }} />

      <div className="container text-center section-padding">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <h1 className="heading">{privacyContent.title}</h1>
            <p className="opacity-50 small-heading">
              {privacyContent.description}
            </p>
          </div>
          <div className="col-12 text-start">
            <ul className="terms-li">
              {privacyContent.sections.map((section, index) => (
                <li key={index}>
                  <h4>{section.title}</h4>
                  <p>{section.content}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Footer data={{ key: privacyContent.title }} />
    </>
  );
};

export default PrivacyPolicy;
