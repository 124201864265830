import React from "react";

const Footer = (props) => {
  let { key } = props.data;
  return (
    <>
      <footer class="footer d-none d-md-block">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="footer-logo" style={{ cursor: 'pointer' }} onClick={() => {
                window.location.href = '/';
              }}>
                <img src="/assets/img/logo.png" alt="logo" style={{ maxWidth: '250px' }} />
              </div>
              <p class="footer-description">
                Generate high-quality AI image headshots effortlessly in minutes
                with <b>Aiheadshotapi.</b>
              </p>
              <p class="footer-contact">
                <a href="tel:+919157995645">
                  <span className="pe-1">
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.50008 17.7721C14.1024 17.7721 17.8334 14.0411 17.8334 9.4388C17.8334 4.83643 14.1024 1.10547 9.50008 1.10547C4.89771 1.10547 1.16675 4.83643 1.16675 9.4388C1.16675 10.5879 1.39931 11.6826 1.81993 12.6786C2.0524 13.2289 2.16863 13.5041 2.18302 13.7121C2.19741 13.9201 2.1362 14.1489 2.01376 14.6065L1.16675 17.7721L4.33239 16.9251C4.78998 16.8027 5.01878 16.7415 5.22676 16.7559C5.43476 16.7702 5.70996 16.8865 6.26037 17.119C7.25629 17.5396 8.351 17.7721 9.50008 17.7721Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.65671 9.75257L7.38249 8.85115C7.68839 8.47123 8.0665 8.11757 8.09617 7.6117C8.10359 7.48393 8.01375 6.91029 7.83392 5.76303C7.76326 5.31216 7.3423 5.27148 6.97769 5.27148C6.50254 5.27148 6.26496 5.27148 6.02904 5.37924C5.73087 5.51544 5.42474 5.89841 5.35756 6.21926C5.30441 6.47312 5.34391 6.64804 5.42291 6.99789C5.75844 8.48382 6.54559 9.95132 7.76615 11.1719C8.98675 12.3925 10.4543 13.1797 11.9402 13.5152C12.29 13.5942 12.4649 13.6337 12.7188 13.5805C13.0397 13.5133 13.4226 13.2072 13.5588 12.909C13.6666 12.6731 13.6666 12.4356 13.6666 11.9604C13.6666 11.5957 13.6259 11.1748 13.175 11.1042C12.0278 10.9243 11.4542 10.8345 11.3263 10.8419C10.8205 10.8716 10.4668 11.2497 10.0869 11.5556L9.1855 12.2813"
                        stroke="black"
                        stroke-width="1.5"
                      />
                    </svg>
                  </span>
                  +91 91579 95645
                </a>
                <a href="mailto:support@aiheadshotapi.com">
                  <span className="pe-1">
                    <svg
                      width="19"
                      height="17"
                      viewBox="0 0 19 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.16675 3.43848L6.9276 6.70262C9.05141 7.90598 9.94875 7.90598 12.0726 6.70262L17.8334 3.43848"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.17989 9.66847C1.23436 12.2231 1.26161 13.5004 2.20421 14.4466C3.14681 15.3928 4.45869 15.4257 7.08244 15.4916C8.6995 15.5323 10.3007 15.5323 11.9177 15.4916C14.5415 15.4257 15.8533 15.3928 16.796 14.4466C17.7386 13.5004 17.7658 12.2231 17.8202 9.66847C17.8378 8.84705 17.8378 8.03055 17.8202 7.20914C17.7658 4.65452 17.7386 3.37721 16.796 2.43102C15.8533 1.48483 14.5415 1.45187 11.9177 1.38594C10.3007 1.34531 8.6995 1.34531 7.08243 1.38594C4.45869 1.45185 3.14681 1.48481 2.20421 2.43101C1.2616 3.3772 1.23436 4.65451 1.17988 7.20914C1.16236 8.03055 1.16237 8.84705 1.17989 9.66847Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  support@aiheadshotapi.com
                </a>
              </p>
              <ul class="footer-nav active-nav-custom" style={{ margin: '30px 0' }}>
                <li>
                  <a href="/" className={key == "Home" ? "active" : ""}>
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="/pricing"
                    className={key == "Pricing" ? "active" : ""}
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="/apidocs"
                    className={key == "API Docs" ? "active" : ""}
                  >
                    API Docs
                  </a>
                </li>
                <li>
                  <a
                    href="/aboutus"
                    className={key == "About Us" ? "active" : ""}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/contactus"
                    className={key == "Contact Us" ? "active" : ""}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <div className="row flex-wrap justify-content-between align-items-center">
              <p className="col-12 col-md-6 m-0 text-start">
                Copyright © 2024. Aiheadshotapi All rights reserved.
              </p>
              <div className="col-12 col-md-6 text-end">
                <p className="m-0 responsive-footer-nav">
                  <a
                    href="/PrivacyPolicy"
                    className={key == "Privacy Policy" ? "active" : ""}
                  >
                    Privacy Policy
                  </a>
                  &nbsp;&nbsp;
                  &bull;
                  &nbsp;&nbsp;
                  <a
                    href="/TermsAndConditions"
                    className={key == "Terms & Conditions" ? "active" : ""}
                  >
                    Terms & Conditions
                  </a>
                  &nbsp;&nbsp;
                  {/* <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.6008 1.02989C20.6786 0.771456 18.2164 0.77147 15.0818 0.771485H14.9181C11.7835 0.77147 9.32134 0.771456 7.39906 1.02989C5.4292 1.29473 3.86629 1.84855 2.63831 3.07653C1.41032 4.30452 0.856502 5.86743 0.591672 7.83727C0.333223 9.75956 0.333238 12.2217 0.333252 15.3563V15.52C0.333238 18.6547 0.333223 21.1168 0.591672 23.0391C0.856502 25.0088 1.41032 26.5718 2.63831 27.7998C3.86629 29.0278 5.4292 29.5815 7.39906 29.8464C9.32134 30.1048 11.7834 30.1048 14.9181 30.1048H15.0818C18.2164 30.1048 20.6786 30.1048 22.6008 29.8464C24.5707 29.5815 26.1336 29.0278 27.3616 27.7998C28.5896 26.5718 29.1433 25.0088 29.4082 23.0391C29.6666 21.1168 29.6666 18.6547 29.6666 15.52V15.3563C29.6666 12.2216 29.6666 9.75956 29.4082 7.83727C29.1433 5.86743 28.5896 4.30452 27.3616 3.07653C26.1336 1.84855 24.5707 1.29473 22.6008 1.02989ZM7.84546 7.21051C7.44246 7.21051 7.07342 7.43629 6.88995 7.79511C6.70648 8.15394 6.7395 8.5853 6.97547 8.91201L12.4409 16.4796L7.08662 21.8338C6.66751 22.2529 6.66751 22.9323 7.08662 23.3514C7.50572 23.7705 8.18521 23.7705 8.60432 23.3514L13.7138 18.242L17.3097 23.2209C17.5114 23.5004 17.8351 23.6658 18.1796 23.6658H22.1544C22.5575 23.6658 22.9265 23.44 23.1099 23.0811C23.2934 22.7224 23.2603 22.291 23.0244 21.9643L17.5591 14.3967L22.9133 9.04253C23.3323 8.62343 23.3323 7.94393 22.9133 7.52483C22.4942 7.10574 21.8147 7.10574 21.3956 7.52483L16.2861 12.6343L12.6902 7.65535C12.4884 7.37599 12.1647 7.21051 11.8202 7.21051H7.84546Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a>
                  <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.66659 0.771484C4.2521 0.771484 2.89554 1.33339 1.89535 2.33358C0.895155 3.33378 0.333252 4.69033 0.333252 6.10482V24.7715C0.333252 26.186 0.895155 27.5425 1.89535 28.5427C2.89554 29.5429 4.2521 30.1048 5.66659 30.1048H24.3333C25.7477 30.1048 27.1043 29.5429 28.1045 28.5427C29.1047 27.5425 29.6666 26.186 29.6666 24.7715V6.10482C29.6666 4.69033 29.1047 3.33378 28.1045 2.33358C27.1043 1.33339 25.7477 0.771484 24.3333 0.771484H5.66659ZM11.9093 9.13548C11.9093 7.97831 12.3688 6.86851 13.187 6.05014C14.0051 5.23177 15.1147 4.77184 16.2719 4.77148H20.1519C20.4186 4.77148 20.6359 4.98882 20.6359 5.25682V9.13548C20.6359 9.40215 20.4186 9.61948 20.1519 9.61948H18.2119C18.021 9.61948 17.8319 9.65712 17.6554 9.73024C17.479 9.80336 17.3188 9.91053 17.1838 10.0456C17.0488 10.1807 16.9418 10.3411 16.8688 10.5176C16.7959 10.6941 16.7584 10.8832 16.7586 11.0742V12.5288H20.1519C20.2255 12.5291 20.2981 12.546 20.3641 12.5785C20.4302 12.6109 20.488 12.6579 20.5332 12.7159C20.5784 12.774 20.6099 12.8416 20.6251 12.9136C20.6404 12.9856 20.639 13.0601 20.6213 13.1315L19.6519 17.0115C19.6255 17.1161 19.5647 17.209 19.4795 17.2752C19.3942 17.3414 19.2892 17.3771 19.1813 17.3768H16.7573V25.6195C16.7573 25.7482 16.7061 25.8717 16.6151 25.9627C16.5241 26.0537 16.4006 26.1048 16.2719 26.1048H12.3933C12.2648 26.1045 12.1417 26.0532 12.0509 25.9622C11.9602 25.8712 11.9093 25.748 11.9093 25.6195V17.3782H9.48525C9.35653 17.3782 9.23309 17.327 9.14207 17.236C9.05105 17.145 8.99992 17.0215 8.99992 16.8928V13.0128C8.99992 12.7462 9.21725 12.5288 9.48525 12.5288H11.9093V9.13548Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a>
                  <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.61897 0.771484C3.1475 0.771484 0.333252 3.58573 0.333252 7.0572V23.8191C0.333252 27.2906 3.1475 30.1048 6.61897 30.1048H23.3811C26.8524 30.1048 29.6666 27.2906 29.6666 23.8191V7.0572C29.6666 3.58573 26.8524 0.771484 23.3811 0.771484H6.61897ZM25.1903 7.0664C25.1903 8.06634 24.3798 8.87686 23.3798 8.87686C22.3799 8.87686 21.5692 8.06634 21.5692 7.0664C21.5692 6.06624 22.3799 5.25573 23.3798 5.25573C24.3798 5.25573 25.1903 6.06624 25.1903 7.0664ZM15.0004 10.4166C12.2269 10.4166 9.97884 12.6648 9.97884 15.438C9.97884 18.2112 12.2269 20.4595 15.0004 20.4595C17.7738 20.4595 20.0217 18.2112 20.0217 15.438C20.0217 12.6648 17.7736 10.4166 15.0004 10.4166ZM7.56482 15.438C7.56482 11.3315 10.8937 8.00241 15.0004 8.00241C19.107 8.00241 22.4357 11.3315 22.4357 15.438C22.4357 19.5444 19.1068 22.8735 15.0004 22.8735C10.8939 22.8735 7.56482 19.5444 7.56482 15.438Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a>
                  <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.66683 0.771484C4.25234 0.771484 2.89579 1.33339 1.89559 2.33358C0.895399 3.33378 0.333496 4.69033 0.333496 6.10482V24.7715C0.333496 26.186 0.895399 27.5425 1.89559 28.5427C2.89579 29.5429 4.25234 30.1048 5.66683 30.1048H24.3335C25.748 30.1048 27.1045 29.5429 28.1047 28.5427C29.1049 27.5425 29.6668 26.186 29.6668 24.7715V6.10482C29.6668 4.69033 29.1049 3.33378 28.1047 2.33358C27.1045 1.33339 25.748 0.771484 24.3335 0.771484H5.66683ZM5.00016 15.4382C5.00016 14.0062 5.06683 12.6555 5.15616 11.5222C5.2131 10.7066 5.56298 9.93923 6.14127 9.36143C6.71957 8.78362 7.48728 8.4344 8.30283 8.37815C10.5321 8.22828 12.7659 8.15713 15.0002 8.16482C17.8762 8.16482 20.0828 8.26348 21.6975 8.37815C22.513 8.4344 23.2808 8.78362 23.8591 9.36143C24.4373 9.93923 24.7872 10.7066 24.8442 11.5222C24.9322 12.6555 25.0002 14.0062 25.0002 15.4382C25.0002 16.9222 24.9282 18.3182 24.8348 19.4755C24.7015 21.1275 23.4428 22.3888 21.8188 22.5022C19.549 22.6496 17.2748 22.7194 15.0002 22.7115C12.0962 22.7115 9.8135 22.6155 8.1815 22.5022C6.5575 22.3888 5.29883 21.1275 5.1655 19.4755C5.05705 18.1324 5.0019 16.7856 5.00016 15.4382ZM12.5148 12.0448L18.1815 15.4382L12.5148 18.8328V12.0448Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer class="footer d-md-none">
        <div class="container">
          <div class="row">
            <div class="col-12 text-start">
              <div class="footer-logo text-star">
                <img src="/assets/img/logo.png" alt="logo" style={{ cursor: 'pointer' }} onClick={() => {
                  window.location.href = '/';
                }} />
              </div>
              <p class="footer-description">
                Generate high-quality AI image headshots effortlessly in minutes
                with <b>Aiheadshotapi.</b>
              </p>
              <p class="footer-contact">
                <a href="tel:+919157995645">
                  <span className="pe-1">
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.50008 17.7721C14.1024 17.7721 17.8334 14.0411 17.8334 9.4388C17.8334 4.83643 14.1024 1.10547 9.50008 1.10547C4.89771 1.10547 1.16675 4.83643 1.16675 9.4388C1.16675 10.5879 1.39931 11.6826 1.81993 12.6786C2.0524 13.2289 2.16863 13.5041 2.18302 13.7121C2.19741 13.9201 2.1362 14.1489 2.01376 14.6065L1.16675 17.7721L4.33239 16.9251C4.78998 16.8027 5.01878 16.7415 5.22676 16.7559C5.43476 16.7702 5.70996 16.8865 6.26037 17.119C7.25629 17.5396 8.351 17.7721 9.50008 17.7721Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.65671 9.75257L7.38249 8.85115C7.68839 8.47123 8.0665 8.11757 8.09617 7.6117C8.10359 7.48393 8.01375 6.91029 7.83392 5.76303C7.76326 5.31216 7.3423 5.27148 6.97769 5.27148C6.50254 5.27148 6.26496 5.27148 6.02904 5.37924C5.73087 5.51544 5.42474 5.89841 5.35756 6.21926C5.30441 6.47312 5.34391 6.64804 5.42291 6.99789C5.75844 8.48382 6.54559 9.95132 7.76615 11.1719C8.98675 12.3925 10.4543 13.1797 11.9402 13.5152C12.29 13.5942 12.4649 13.6337 12.7188 13.5805C13.0397 13.5133 13.4226 13.2072 13.5588 12.909C13.6666 12.6731 13.6666 12.4356 13.6666 11.9604C13.6666 11.5957 13.6259 11.1748 13.175 11.1042C12.0278 10.9243 11.4542 10.8345 11.3263 10.8419C10.8205 10.8716 10.4668 11.2497 10.0869 11.5556L9.1855 12.2813"
                        stroke="black"
                        stroke-width="1.5"
                      />
                    </svg>
                  </span>
                  +91 91579 95645
                </a>
                <br />
                <a href="mailto:support@aiheadshotapi.com">
                  <span className="pe-1">
                    <svg
                      width="19"
                      height="17"
                      viewBox="0 0 19 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.16675 3.43848L6.9276 6.70262C9.05141 7.90598 9.94875 7.90598 12.0726 6.70262L17.8334 3.43848"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.17989 9.66847C1.23436 12.2231 1.26161 13.5004 2.20421 14.4466C3.14681 15.3928 4.45869 15.4257 7.08244 15.4916C8.6995 15.5323 10.3007 15.5323 11.9177 15.4916C14.5415 15.4257 15.8533 15.3928 16.796 14.4466C17.7386 13.5004 17.7658 12.2231 17.8202 9.66847C17.8378 8.84705 17.8378 8.03055 17.8202 7.20914C17.7658 4.65452 17.7386 3.37721 16.796 2.43102C15.8533 1.48483 14.5415 1.45187 11.9177 1.38594C10.3007 1.34531 8.6995 1.34531 7.08243 1.38594C4.45869 1.45185 3.14681 1.48481 2.20421 2.43101C1.2616 3.3772 1.23436 4.65451 1.17988 7.20914C1.16236 8.03055 1.16237 8.84705 1.17989 9.66847Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  support@aiheadshotapi.com
                </a>
              </p>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-5">
                  <ul class="responsive-footer-nav active-nav-custom">
                    <h3>Quick Links</h3>
                    <li>
                      <a href="#" className={key == "Home" ? "active" : ""}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#" className={key == "Pricing" ? "active" : ""}>
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a href="#" className={key == "API Docs" ? "active" : ""}>
                        API Docs
                      </a>
                    </li>
                    <li>
                      <a href="#" className={key == "About Us" ? "active" : ""}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className={key == "Contact Us" ? "active" : ""}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-5">
                  <ul class="responsive-footer-nav">
                    <h3>Legal Rights</h3>
                    <li>
                      <a
                        href="/PrivacyPolicy"
                        className={key == "Privacy Policy" ? "active" : ""}
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/TermsAndConditions"
                        className={key == "Terms & Conditions" ? "active" : ""}
                      >
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <div className="row flex-wrap justify-content-center align-items-center">
              <p className="col-12 col-md-6 m-0 text-center">
                Copyright © 2024. Aiheadshotapi All rights reserved.
              </p>
              <div className="col-12 col-md-6 text-center">
                <p className="m-0">
                  {/* <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.6008 1.02989C20.6786 0.771456 18.2164 0.77147 15.0818 0.771485H14.9181C11.7835 0.77147 9.32134 0.771456 7.39906 1.02989C5.4292 1.29473 3.86629 1.84855 2.63831 3.07653C1.41032 4.30452 0.856502 5.86743 0.591672 7.83727C0.333223 9.75956 0.333238 12.2217 0.333252 15.3563V15.52C0.333238 18.6547 0.333223 21.1168 0.591672 23.0391C0.856502 25.0088 1.41032 26.5718 2.63831 27.7998C3.86629 29.0278 5.4292 29.5815 7.39906 29.8464C9.32134 30.1048 11.7834 30.1048 14.9181 30.1048H15.0818C18.2164 30.1048 20.6786 30.1048 22.6008 29.8464C24.5707 29.5815 26.1336 29.0278 27.3616 27.7998C28.5896 26.5718 29.1433 25.0088 29.4082 23.0391C29.6666 21.1168 29.6666 18.6547 29.6666 15.52V15.3563C29.6666 12.2216 29.6666 9.75956 29.4082 7.83727C29.1433 5.86743 28.5896 4.30452 27.3616 3.07653C26.1336 1.84855 24.5707 1.29473 22.6008 1.02989ZM7.84546 7.21051C7.44246 7.21051 7.07342 7.43629 6.88995 7.79511C6.70648 8.15394 6.7395 8.5853 6.97547 8.91201L12.4409 16.4796L7.08662 21.8338C6.66751 22.2529 6.66751 22.9323 7.08662 23.3514C7.50572 23.7705 8.18521 23.7705 8.60432 23.3514L13.7138 18.242L17.3097 23.2209C17.5114 23.5004 17.8351 23.6658 18.1796 23.6658H22.1544C22.5575 23.6658 22.9265 23.44 23.1099 23.0811C23.2934 22.7224 23.2603 22.291 23.0244 21.9643L17.5591 14.3967L22.9133 9.04253C23.3323 8.62343 23.3323 7.94393 22.9133 7.52483C22.4942 7.10574 21.8147 7.10574 21.3956 7.52483L16.2861 12.6343L12.6902 7.65535C12.4884 7.37599 12.1647 7.21051 11.8202 7.21051H7.84546Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a>
                  <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.66659 0.771484C4.2521 0.771484 2.89554 1.33339 1.89535 2.33358C0.895155 3.33378 0.333252 4.69033 0.333252 6.10482V24.7715C0.333252 26.186 0.895155 27.5425 1.89535 28.5427C2.89554 29.5429 4.2521 30.1048 5.66659 30.1048H24.3333C25.7477 30.1048 27.1043 29.5429 28.1045 28.5427C29.1047 27.5425 29.6666 26.186 29.6666 24.7715V6.10482C29.6666 4.69033 29.1047 3.33378 28.1045 2.33358C27.1043 1.33339 25.7477 0.771484 24.3333 0.771484H5.66659ZM11.9093 9.13548C11.9093 7.97831 12.3688 6.86851 13.187 6.05014C14.0051 5.23177 15.1147 4.77184 16.2719 4.77148H20.1519C20.4186 4.77148 20.6359 4.98882 20.6359 5.25682V9.13548C20.6359 9.40215 20.4186 9.61948 20.1519 9.61948H18.2119C18.021 9.61948 17.8319 9.65712 17.6554 9.73024C17.479 9.80336 17.3188 9.91053 17.1838 10.0456C17.0488 10.1807 16.9418 10.3411 16.8688 10.5176C16.7959 10.6941 16.7584 10.8832 16.7586 11.0742V12.5288H20.1519C20.2255 12.5291 20.2981 12.546 20.3641 12.5785C20.4302 12.6109 20.488 12.6579 20.5332 12.7159C20.5784 12.774 20.6099 12.8416 20.6251 12.9136C20.6404 12.9856 20.639 13.0601 20.6213 13.1315L19.6519 17.0115C19.6255 17.1161 19.5647 17.209 19.4795 17.2752C19.3942 17.3414 19.2892 17.3771 19.1813 17.3768H16.7573V25.6195C16.7573 25.7482 16.7061 25.8717 16.6151 25.9627C16.5241 26.0537 16.4006 26.1048 16.2719 26.1048H12.3933C12.2648 26.1045 12.1417 26.0532 12.0509 25.9622C11.9602 25.8712 11.9093 25.748 11.9093 25.6195V17.3782H9.48525C9.35653 17.3782 9.23309 17.327 9.14207 17.236C9.05105 17.145 8.99992 17.0215 8.99992 16.8928V13.0128C8.99992 12.7462 9.21725 12.5288 9.48525 12.5288H11.9093V9.13548Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a>
                  <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.61897 0.771484C3.1475 0.771484 0.333252 3.58573 0.333252 7.0572V23.8191C0.333252 27.2906 3.1475 30.1048 6.61897 30.1048H23.3811C26.8524 30.1048 29.6666 27.2906 29.6666 23.8191V7.0572C29.6666 3.58573 26.8524 0.771484 23.3811 0.771484H6.61897ZM25.1903 7.0664C25.1903 8.06634 24.3798 8.87686 23.3798 8.87686C22.3799 8.87686 21.5692 8.06634 21.5692 7.0664C21.5692 6.06624 22.3799 5.25573 23.3798 5.25573C24.3798 5.25573 25.1903 6.06624 25.1903 7.0664ZM15.0004 10.4166C12.2269 10.4166 9.97884 12.6648 9.97884 15.438C9.97884 18.2112 12.2269 20.4595 15.0004 20.4595C17.7738 20.4595 20.0217 18.2112 20.0217 15.438C20.0217 12.6648 17.7736 10.4166 15.0004 10.4166ZM7.56482 15.438C7.56482 11.3315 10.8937 8.00241 15.0004 8.00241C19.107 8.00241 22.4357 11.3315 22.4357 15.438C22.4357 19.5444 19.1068 22.8735 15.0004 22.8735C10.8939 22.8735 7.56482 19.5444 7.56482 15.438Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a>
                  <a href="#" className="px-1">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.66683 0.771484C4.25234 0.771484 2.89579 1.33339 1.89559 2.33358C0.895399 3.33378 0.333496 4.69033 0.333496 6.10482V24.7715C0.333496 26.186 0.895399 27.5425 1.89559 28.5427C2.89579 29.5429 4.25234 30.1048 5.66683 30.1048H24.3335C25.748 30.1048 27.1045 29.5429 28.1047 28.5427C29.1049 27.5425 29.6668 26.186 29.6668 24.7715V6.10482C29.6668 4.69033 29.1049 3.33378 28.1047 2.33358C27.1045 1.33339 25.748 0.771484 24.3335 0.771484H5.66683ZM5.00016 15.4382C5.00016 14.0062 5.06683 12.6555 5.15616 11.5222C5.2131 10.7066 5.56298 9.93923 6.14127 9.36143C6.71957 8.78362 7.48728 8.4344 8.30283 8.37815C10.5321 8.22828 12.7659 8.15713 15.0002 8.16482C17.8762 8.16482 20.0828 8.26348 21.6975 8.37815C22.513 8.4344 23.2808 8.78362 23.8591 9.36143C24.4373 9.93923 24.7872 10.7066 24.8442 11.5222C24.9322 12.6555 25.0002 14.0062 25.0002 15.4382C25.0002 16.9222 24.9282 18.3182 24.8348 19.4755C24.7015 21.1275 23.4428 22.3888 21.8188 22.5022C19.549 22.6496 17.2748 22.7194 15.0002 22.7115C12.0962 22.7115 9.8135 22.6155 8.1815 22.5022C6.5575 22.3888 5.29883 21.1275 5.1655 19.4755C5.05705 18.1324 5.0019 16.7856 5.00016 15.4382ZM12.5148 12.0448L18.1815 15.4382L12.5148 18.8328V12.0448Z"
                        fill="#9371F0"
                      />
                    </svg>
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
