import React, { useEffect, useState } from "react";

const Header = (props) => {
  let { key } = props.data;
  const [togglerIcon, setTogglerIcon] = useState(false);

  const customRedirect = () => {
    window.location.href = "https://app.aiheadshotapi.com/";
  };
  return (
    <div className="navbar-main">
      <div className="container">
        {/* Mobile View */}
        <nav className="navbar navbar-expand-lg mobile-navbar d-lg-none">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setTogglerIcon(!togglerIcon)}
            >
              {!togglerIcon ? (
                <span className="navbar-toggler-icon custom-toggle-icon">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.33301 1.1665H14.6663"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.33301 7H14.6663"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.33301 12.8335H14.6663"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              ) : (
                <span className="navbar-toggler-close-icon custom-toggle-icon">
                  ✖
                </span>
              )}
            </button>
            <a className="navbar-brand logo mx-auto" href="/">
              <img
                src="/assets/img/logo.png"
                alt="logo"
                style={{ maxWidth: "150px" }}
              />
            </a>
            <a href="https://app.aiheadshotapi.com/" style={{padding: '4px 12px'}}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.08398 9.00016C1.08398 5.26821 1.08398 3.40224 2.24335 2.24286C3.40273 1.0835 5.2687 1.0835 9.00065 1.0835C12.7326 1.0835 14.5986 1.0835 15.758 2.24286C16.9173 3.40224 16.9173 5.26821 16.9173 9.00016C16.9173 12.7321 16.9173 14.5981 15.758 15.7575C14.5986 16.9168 12.7326 16.9168 9.00065 16.9168C5.2687 16.9168 3.40273 16.9168 2.24335 15.7575C1.08398 14.5981 1.08398 12.7321 1.08398 9.00016Z"
                  stroke="black"
                  stroke-width="1.25"
                />
                <path
                  d="M5.25098 13.1668C7.19406 11.1317 10.787 11.0358 12.751 13.1668M11.0802 6.91683C11.0802 8.06741 10.1461 9.00016 8.99389 9.00016C7.84173 9.00016 6.90762 8.06741 6.90762 6.91683C6.90762 5.76624 7.84173 4.8335 8.99389 4.8335C10.1461 4.8335 11.0802 5.76624 11.0802 6.91683Z"
                  stroke="black"
                  stroke-width="1.25"
                  stroke-linecap="round"
                />
              </svg>
            </a>

            <div
              className="collapse navbar-collapse active-nav-custom"
              id="navbarNav"
            >
              <ul className="navbar-nav mobile-nav-links">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    <span className="pe-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.66699 12H9.33366"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.56727 8.80916C1.33192 7.27763 1.21425 6.51192 1.50379 5.83308C1.79332 5.15424 2.43569 4.68978 3.72043 3.76087L4.68033 3.06683C6.27853 1.91128 7.07761 1.3335 7.99967 1.3335C8.92174 1.3335 9.72081 1.91128 11.319 3.06683L12.2789 3.76087C13.5637 4.68978 14.206 5.15424 14.4955 5.83308C14.7851 6.51192 14.6674 7.27763 14.4321 8.80916L14.2314 10.1151C13.8977 12.2861 13.7309 13.3716 12.9523 14.0192C12.1737 14.6668 11.0355 14.6668 8.75887 14.6668H7.24047C4.96389 14.6668 3.82561 14.6668 3.04701 14.0192C2.2684 13.3716 2.10159 12.2861 1.76796 10.1151L1.56727 8.80916Z"
                          stroke="black"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/pricing">
                    <span className="pe-2">
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66634 7.00016C9.66634 7.92063 8.92014 8.66683 7.99967 8.66683C7.07921 8.66683 6.33301 7.92063 6.33301 7.00016C6.33301 6.0797 7.07921 5.3335 7.99967 5.3335C8.92014 5.3335 9.66634 6.0797 9.66634 7.00016Z"
                          stroke="black"
                        />
                        <path
                          d="M12.6663 6.42765C12.4496 6.39625 12.227 6.37238 11.9997 6.35645M3.99967 7.64351C3.77232 7.62764 3.54975 7.60371 3.33301 7.57231"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.99967 12.0002C7.11134 12.4151 5.94439 12.6668 4.66634 12.6668C3.95573 12.6668 3.27947 12.589 2.66634 12.4486C1.66605 12.2194 1.33301 11.6178 1.33301 10.5908V3.40948C1.33301 2.75292 2.02636 2.30198 2.66634 2.44856C3.27947 2.589 3.95573 2.66683 4.66634 2.66683C5.94439 2.66683 7.11134 2.41507 7.99967 2.00016C8.88801 1.58526 10.0549 1.3335 11.333 1.3335C12.0436 1.3335 12.7199 1.41133 13.333 1.55176C14.3875 1.79328 14.6663 2.41374 14.6663 3.40948V10.5908C14.6663 11.2474 13.973 11.6984 13.333 11.5518C12.7199 11.4113 12.0436 11.3335 11.333 11.3335C10.0549 11.3335 8.88801 11.5852 7.99967 12.0002Z"
                          stroke="black"
                        />
                      </svg>
                    </span>
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/apiDocs" className="nav-link" >
                    <span className="pe-2">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.666992 6.99984C0.666992 4.01428 0.666992 2.5215 1.59449 1.594C2.52199 0.666504 4.01477 0.666504 7.00033 0.666504C9.98586 0.666504 11.4787 0.666504 12.4062 1.594C13.3337 2.5215 13.3337 4.01428 13.3337 6.99984C13.3337 9.98537 13.3337 11.4782 12.4062 12.4057C11.4787 13.3332 9.98586 13.3332 7.00033 13.3332C4.01477 13.3332 2.52199 13.3332 1.59449 12.4057C0.666992 11.4782 0.666992 9.98537 0.666992 6.99984Z"
                          stroke="black"
                        />
                        <path
                          d="M3.00033 8L4.00033 5L5.25033 8M3.00033 8L2.66699 9M3.00033 8H5.25033M5.25033 8L5.66699 9"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33301 7V5.46667C7.33301 5.34258 7.33301 5.28054 7.34934 5.23033C7.38227 5.12885 7.46187 5.04929 7.56334 5.01631C7.61354 5 7.67561 5 7.79967 5H8.66634C9.21861 5 9.66634 5.44771 9.66634 6C9.66634 6.55227 9.21861 7 8.66634 7H7.33301ZM7.33301 7V9"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.333 5V9"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    API Docs
                  </a>
                </li>
                <li className="nav-item d-flex">
                  <a href="/aboutus" className="nav-link text-center col-4">
                    About Us
                  </a>
                  <a
                    href="/contactus"
                    className="nav-link text-center col-4"
                    style={{
                      borderRight: "1px solid #0000000D",
                      borderLeft: "1px solid #0000000D",
                    }}
                  >
                    Contact Us
                  </a>
                  <a href="/PrivacyPolicy" className="nav-link text-center col-4">
                    Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span className="pe-2">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.51809 7.96176C1.88607 6.8597 1.58091 5.95982 1.3969 5.04764C1.12475 3.69855 1.74755 2.3807 2.77926 1.53981C3.21531 1.18442 3.71516 1.30584 3.97301 1.76843L4.55513 2.81277C5.01653 3.64054 5.24723 4.05442 5.20147 4.49322C5.15572 4.93202 4.84459 5.2894 4.22232 6.00417L2.51809 7.96176ZM2.51809 7.96176C3.79735 10.1924 5.8049 12.201 8.03807 13.4818M8.03807 13.4818C9.14014 14.1138 10.04 14.419 10.9522 14.603C12.3013 14.8751 13.6191 14.2523 14.46 13.2206C14.8154 12.7846 14.694 12.2847 14.2314 12.0268L13.1871 11.4447C12.3593 10.9833 11.9454 10.7526 11.5066 10.7984C11.0678 10.8441 10.7104 11.1552 9.99567 11.7775L8.03807 13.4818Z"
                          stroke="black"
                          stroke-width="1.2"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    +91 98985 98985
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    <span className="pe-2">
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.33301 3L5.94169 5.61131C7.64074 6.574 8.35861 6.574 10.0577 5.61131L14.6663 3"
                          stroke="black"
                          stroke-width="1.2"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.34352 7.9839C1.3871 10.0276 1.40889 11.0494 2.16298 11.8064C2.91706 12.5634 3.96656 12.5897 6.06556 12.6424C7.35921 12.675 8.64014 12.675 9.93381 12.6424C12.0328 12.5897 13.0823 12.5634 13.8364 11.8064C14.5905 11.0494 14.6123 10.0276 14.6558 7.9839C14.6699 7.32676 14.6699 6.67356 14.6558 6.01643C14.6123 3.97274 14.5905 2.95089 13.8364 2.19394C13.0823 1.43698 12.0328 1.41062 9.93381 1.35788C8.64014 1.32537 7.35921 1.32537 6.06555 1.35787C3.96656 1.4106 2.91706 1.43697 2.16297 2.19393C1.40889 2.95088 1.3871 3.97273 1.34351 6.01643C1.3295 6.67356 1.32951 7.32676 1.34352 7.9839Z"
                          stroke="black"
                          stroke-width="1.2"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    help@apiadmin.in
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://app.aiheadshotapi.com/">
                    <span className="pe-2">
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.38505 10.3212C2.44187 10.8828 -0.0310913 12.0296 1.47511 13.4646C2.21088 14.1655 3.03033 14.6668 4.06058 14.6668H9.9394C10.9697 14.6668 11.7891 14.1655 12.5249 13.4646C14.0311 12.0296 11.5581 10.8828 10.6149 10.3212C8.4032 9.00425 5.5968 9.00425 3.38505 10.3212Z"
                          stroke="black"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 4.3335C10 5.99035 8.65687 7.3335 7 7.3335C5.34315 7.3335 4 5.99035 4 4.3335C4 2.67664 5.34315 1.3335 7 1.3335C8.65687 1.3335 10 2.67664 10 4.3335Z"
                          stroke="black"
                          stroke-width="1.2"
                        />
                      </svg>
                    </span>
                    Login / Register
                  </a>
                </li>
              </ul>
              <div className="button-group d-flex mt-3">
                <button
                  className="custom-btn mx-auto"
                  style={{ borderRadius: "8px" }}
                  onClick={() => {
                    window.location.href = "https://app.aiheadshotapi.com/";
                  }}
                >
                  <span className="pe-2">
                    <svg
                      width="13"
                      height="14"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 6.8335C11.2908 6.8335 11.1037 6.96376 11.0311 7.15996L10.9574 7.3591C10.9063 7.49723 10.8575 7.61543 10.8322 7.66956C10.7781 7.69483 10.6637 7.73976 10.5256 7.7909L10.3265 7.86456C10.1303 7.93716 10 8.1243 10 8.3335C10 8.5427 10.1303 8.72983 10.3265 8.80243L10.5256 8.8761C10.6637 8.92723 10.7781 8.97216 10.8322 8.99743C10.8575 9.05156 10.9063 9.16976 10.9574 9.3079L11.0311 9.50703C11.1037 9.70323 11.2908 9.8335 11.5 9.8335C11.7092 9.8335 11.8963 9.70323 11.9689 9.50703L12.0426 9.3079C12.0937 9.16976 12.1425 9.05156 12.1678 8.99743C12.2219 8.97216 12.3363 8.92723 12.4744 8.8761L12.6735 8.80243C12.8697 8.72983 13 8.5427 13 8.3335C13 8.1243 12.8697 7.93716 12.6735 7.86456L12.4744 7.7909C12.3363 7.73976 12.2219 7.69483 12.1678 7.66956C12.1425 7.61543 12.0937 7.49723 12.0426 7.3591L11.9689 7.15996C11.8963 6.96376 11.7092 6.8335 11.5 6.8335Z"
                        fill="white"
                      />
                      <path
                        d="M8.15823 3.62645C8.49843 3.45785 8.8979 3.45785 9.23817 3.62645C9.4199 3.71653 9.6143 3.9117 9.76783 4.06593C9.9221 4.21948 10.1172 4.41385 10.2073 4.59562C10.3759 4.93585 10.3759 5.33532 10.2073 5.67553C10.1172 5.85733 9.9617 6.0122 9.80743 6.16573L8.5409 7.43233L6.40137 5.29295L7.66803 4.02632C7.82157 3.87208 7.97643 3.71653 8.15823 3.62645Z"
                        fill="white"
                      />
                      <path
                        d="M5.69387 6L7.83334 8.13933L2.66574 13.3069C2.51219 13.4612 2.35732 13.6167 2.17555 13.7068C1.83532 13.8754 1.43585 13.8754 1.09562 13.7068C0.913855 13.6167 0.719482 13.4216 0.565935 13.2673C0.411702 13.1138 0.216528 12.9194 0.126455 12.7377C-0.0421517 12.3974 -0.0421517 11.9979 0.126455 11.6577C0.216528 11.4759 0.372082 11.3211 0.526315 11.1675L5.69387 6Z"
                        fill="white"
                      />
                      <path
                        d="M5.5 0.166504C5.2908 0.166504 5.10368 0.296771 5.03107 0.492984L4.95738 0.692137C4.90628 0.830237 4.85751 0.948411 4.83222 1.00256C4.77807 1.02786 4.66373 1.07278 4.52563 1.12388L4.32648 1.19758C4.13027 1.27018 4 1.45728 4 1.6665C4 1.87572 4.13027 2.06282 4.32648 2.13543L4.52563 2.20912C4.66373 2.26022 4.77807 2.30515 4.83222 2.33045C4.85751 2.3846 4.90628 2.50277 4.95738 2.64087L5.03107 2.84002C5.10368 3.03624 5.2908 3.1665 5.5 3.1665C5.7092 3.1665 5.89633 3.03624 5.96893 2.84002L6.0426 2.64087C6.09373 2.50277 6.14247 2.3846 6.1678 2.33045C6.22193 2.30515 6.33627 2.26022 6.4744 2.20912L6.67353 2.13543C6.86973 2.06282 7 1.87572 7 1.6665C7 1.45728 6.86973 1.27018 6.67353 1.19758L6.4744 1.12388C6.33627 1.07278 6.22193 1.02786 6.1678 1.00256C6.14247 0.948411 6.09373 0.830237 6.0426 0.692137L5.96893 0.492984C5.89633 0.296771 5.7092 0.166504 5.5 0.166504Z"
                        fill="white"
                      />
                      <path
                        d="M11.5 0.166504C11.2908 0.166504 11.1037 0.296771 11.0311 0.492984L10.9574 0.692137C10.9063 0.830237 10.8575 0.948411 10.8322 1.00256C10.7781 1.02786 10.6637 1.07278 10.5256 1.12388L10.3265 1.19758C10.1303 1.27018 10 1.45728 10 1.6665C10 1.87572 10.1303 2.06282 10.3265 2.13543L10.5256 2.20912C10.6637 2.26022 10.7781 2.30515 10.8322 2.33045C10.8575 2.3846 10.9063 2.50277 10.9574 2.64087L11.0311 2.84002C11.1037 3.03624 11.2908 3.1665 11.5 3.1665C11.7092 3.1665 11.8963 3.03624 11.9689 2.84002L12.0426 2.64087C12.0937 2.50277 12.1425 2.3846 12.1678 2.33045C12.2219 2.30515 12.3363 2.26022 12.4744 2.20912L12.6735 2.13543C12.8697 2.06282 13 1.87572 13 1.6665C13 1.45728 12.8697 1.27018 12.6735 1.19758L12.4744 1.12388C12.3363 1.07278 12.2219 1.02786 12.1678 1.00256C12.1425 0.948411 12.0937 0.830237 12.0426 0.692137L11.9689 0.492984C11.8963 0.296771 11.7092 0.166504 11.5 0.166504Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Access API
                </button>
              </div>
            </div>
          </div>
        </nav>
        {/* Desktop View */}
        <nav className="navbar navbar-expand-lg desktop-navbar d-none d-lg-flex">
          <div className="container-fluid">
            <a className="navbar-brand logo" href="/">
              <img src="/assets/img/logo.png" alt="logo" />
            </a>
            <div
              className="collapse navbar-collapse desktop-nav-links"
              id="navbarNav"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0 custom-header-nav active-nav-custom">
                <li className="nav-item">
                  <a
                    className={`nav-link ${key == "Pricing" ? "active" : ""}`}
                    href="/pricing"
                  >
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${key == "API DOCS" ? "active" : ""}`}
                    href="/apiDocs"
                  >
                    API Docs
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${key == "About Us" ? "active" : ""}`}
                    href="/aboutus"
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      key == "Contact Us" ? "active" : ""
                    }`}
                    href="/contactus"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>

              <div className="d-flex align-items-center">
                <button
                  className="custom-btn-2 me-2 button-border-color"
                  type="button"
                  onClick={customRedirect}
                >
                  Login
                </button>
                <button
                  className="custom-btn button-border-radius"
                  type="button"
                  onClick={() => {
                    window.location.href = "https://app.aiheadshotapi.com/";
                  }}
                >
                  Access API
                  <span className="ps-2">
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.3332 5H1.6665"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.00007 8.33317C9.00007 8.33317 12.3333 5.87824 12.3333 4.99984C12.3333 4.12144 9 1.6665 9 1.6665"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
