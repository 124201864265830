import React from "react";
import Header from "./UI/Header";
import Footer from "./UI/Footer";

const TermsAndConditions = () => {
  const termsContent = {
    title: "Terms & Conditions",
    description:
      "Our Terms & Conditions govern your use of our services, outlining your rights and responsibilities. By using our services, you agree to these terms.",
    sections: [
      {
        title: "1. Terms and conditions",
        content: (
          <>
            These terms and conditions ("Terms", "Agreement") are a set of
            binding rules between Aiheadshotapi.com Sp. z o.o. ("Application
            Developer", "us", "we" or "our") and you ("User","you" or "your"),
            which sets forth the general terms and conditions of your use of the
            Aiheadshotapi Application and any of its products or services
            (collectively, "Application" or "Services", "Aiheadshotapi.com").
            Accepting Terms is mandatory as a condition to use Aiheadshotapi
            Application.
          </>
        ),
      },
      {
        title: "2. Accounts and membership",
        content: (
          <>
            If you create an account (or use your email address) in the
            Aiheadshotapi.com application ("Application"), you are responsible
            for maintaining the security of your account and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it. We may, but have no
            obligation to, monitor and review new accounts (email addresses)
            before you may sign in and use our Services. Providing false contact
            information of any kind may result in the termination of your
            account (email address). You must immediately notify us of any
            unauthorized uses of your account or any other breaches of security.
            We will not be liable for any acts or omissions by you, including
            any damages of any kind incurred as a result of such acts or
            omissions. We may suspend, disable, or delete your account (email
            address) if we determine that you have violated any provision of
            this Terms and Conditions or that your conduct or content would tend
            to damage our reputation or goodwill. If we delete your account for
            the foregoing reasons, you may not re-register for our Services. We
            may block your email address and Internet protocol address to
            prevent further registration. By creating a company account (with
            corporate email address indicating user's company details) (does not
            apply to sole proprietorships) you grant us the right to publicly
            inform (for marketing purposes) of the fact that your company has
            used the Application. We do not guarantee that the results of the
            Application will be (a) unmistakable, (b) in accordance with your
            expectations. Despite our ongoing efforts and work to improve the
            Application, we do not guarantee 24/7 access to the Application.
          </>
        ),
      },
      {
        title: "3. Backups",
        content: (
          <>
            We are not responsible for Content residing in the Aiheadshotapi.com
            Application. In no event shall we be held liable for any loss of any
            Content. It is your sole responsibility to maintain appropriate
            backup of your Content. Notwithstanding the foregoing, on some
            occasions and in certain circumstances, with absolutely no
            obligation, we may be able to restore some or all of your data that
            has been deleted as of a certain date and time when we may have
            backed up data for our own purposes. We make no guarantee that the
            data you need will be available.
          </>
        ),
      },
      {
        title: "4. Links to other applications",
        content: (
          <>
            Although this Application may link to other applications, we are
            not, directly or indirectly, implying any approval, association,
            sponsorship, endorsement, or affiliation with any linked
            application, unless specifically stated herein. Some of the links in
            the Application may be "affiliate links". This means if you click on
            the link and upload an item, the Application Developer will receive
            an affiliate commission. We are not responsible for examining or
            evaluating, and we do not warrant the offerings of, any businesses
            or individuals or the content of their applications. We do not
            assume any responsibility or liability for the actions, products,
            services, and content of any other third-parties. You should
            carefully review the legal statements and other conditions of use of
            any application. Your linking to any other off-site applications is
            at your own risk.
          </>
        ),
      },
      {
        title: "5. Prohibited uses",
        content: (
          <>
            In addition to other terms as set forth in the Agreement, you are
            prohibited from using the Application or its Content:
            <ul className="mb-2">
              <li>For any unlawful purpose;</li>
              <li>
                To solicit others to perform or participate in any unlawful
                acts;
              </li>
              <li>
                To violate any regulations, rules, laws, or local ordinances;
              </li>
              <li>To carry out illegal or fraudulent activity;</li>
              <li>Harm public order and good morals;</li>
              <li>
                To infringe upon or violate any of our rights or rights of third
                parties, in particular our intellectual property rights or the
                intellectual property rights of others;
              </li>
              <li>
                To harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin, or disability;
              </li>
              <li>To submit false or misleading information;</li>
              <li>
                To upload or transmit viruses or any other type of malicious
                code that will or may be used in any way that will affect the
                functionality or operation of the Service or of any related
                application;
              </li>
              <li>To collect or track the personal information of others;</li>
              <li>To spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
              <li>For any obscene or immoral purpose; or</li>
              <li>
                To interfere with or circumvent the security features of the
                Service or any related application.
              </li>
            </ul>
            We reserve the right to terminate your use of the Service or any
            related application for violating any of the prohibited uses.
          </>
        ),
      },
      {
        title: "6. User content",
        content: (
          <>
            You retain ownership of all information and content (including text,
            images, video, audio, and other material) that you upload, publish,
            transmit, link to, or otherwise make available through the
            Application ("User Content"). You are solely responsible for User
            Content that you upload, publish, transmit, link to, or otherwise
            make available through the Application. If you are posting User
            Content belonging to or generated by or on behalf of a third party,
            you warrant that you are authorized to act on behalf of that third
            party and to bind them to these Terms. You acknowledge that any User
            Content (private photos, copyrighted texts etc.) can be used by the
            Application, which can make changes to this Content.
          </>
        ),
      },
      {
        title: "7. Intellectual property rights",
        content: (
          <>
            This Agreement does not transfer to you any intellectual property
            owned by Aiheadshotapi.com Sp. z o.o. or third-parties, and all
            rights, titles, and interests in and to such property will remain
            (as between the parties) solely with Application Developer. All
            trademarks, service marks, graphics, and logos used in connection
            with our Application or Services, are trademarks or registered
            trademarks of Application Developer or Application Developer
            licensors. Other trademarks, service marks, graphics, and logos used
            in connection with our Application or Services may be the trademarks
            of other third-parties. Your use of our Application and Services
            grants you no right or license to reproduce or otherwise use any
            Application Developer or third-party trademarks. The User ensures
            that he is entitled to use the images sent to the Application and
            that its modification by the Application Developer does not violate
            anyone's copyrights. The user assumes all liability for any eventual
            infringement of third-party copyrights that arose through the use of
            the application.
          </>
        ),
      },
      {
        title: "8. Limitation of liability",
        content: (
          <>
            To the fullest extent permitted by applicable law, in no event will
            Application Developer, its affiliates, officers, directors,
            employees, agents, suppliers, or licensors be liable to User for
            (a): any indirect, incidental, special, punitive, cover or
            consequential damages (including, without limitation, damages for
            lost profits, revenue, sales, goodwill, use of content, impact on
            business, business interruption, loss of anticipated savings, loss
            of business opportunity) however caused, under any theory of
            liability, including, without limitation, contract, tort, warranty,
            breach of statutory duty, negligence or otherwise, even if
            Application Developer has been advised as to the possibility of such
            damages or could have foreseen such damages.
          </>
        ),
      },
      {
        title: "9. Indemnification",
        content: (
          <>
            You agree to indemnify and hold Application Developer and its
            affiliates, directors, officers, employees, and agents harmless from
            and against any liabilities, losses, damages, or costs, including
            reasonable attorneys' fees, incurred in connection with or arising
            from any third-party allegations, claims, actions, disputes, or
            demands asserted against any of them as a result of or relating to
            your Content, your use of the Application or Services or any willful
            misconduct on your part.
          </>
        ),
      },
      {
        title: "10. Severability",
        content: (
          <>
            All rights and restrictions contained in this Agreement may be
            exercised and shall be applicable and binding only to the extent
            that they do not violate any applicable laws and are intended to be
            limited to the extent necessary so that they will not render this
            Agreement illegal, invalid, or unenforceable. If any provision or
            part of any provision of this Agreement shall be held to be illegal,
            invalid, or unenforceable by a court of competent jurisdiction, it
            is the intention of the parties that the remaining provisions or
            portions thereof shall constitute their agreement with respect to
            the subject matter hereof, and all such remaining provisions or
            portions thereof shall remain in full force and effect.
          </>
        ),
      },
      {
        title: "11. Dispute resolution",
        content: (
          <>
            These Terms and Conditions shall be governed by and interpreted in
            accordance with the laws of Poland. Any action seeking legal or
            equitable relief arising out of or relating to these Terms and
            Conditions will be resolved by the competent court in Szczecin,
            Poland.
          </>
        ),
      },
      {
        title: "12. Changes and amendments",
        content: (
          <>
            We reserve the right to modify this Terms and Conditions or its
            policies relating to the Application or Services at any time,
            effective upon posting of an updated version of this Agreement in
            the Application. When we do, we will revise the updated date at the
            bottom of this page. Continued use of the Application after any such
            changes shall constitute your consent to such changes.
          </>
        ),
      },
      {
        title: "13. Acceptance of these terms",
        content: (
          <>
            You acknowledge that you have read these Terms & Conditions and
            agree on its provisions. By using the Aiheadshotapi.com or its
            Services you agree to be bound by these Terms & Conditions. If you
            do not agree to abide by the Terms & Conditions, you are not
            authorized to use or access the Application and its Services.
          </>
        ),
      },
      {
        title: "14. Payments",
        content: (
          <>
            We offer various payment methods and pricing options. Detailed
            information about payments and the price list can be found on the
            Application's website at: https://aiheadshotapi.com/plans.
            Regardless of the selected access option, payments for the purchased
            access and subscription period are made in advance. The User may
            cancel the subscription at any time during its duration, with effect
            at the end of the billing month.
          </>
        ),
      },
      {
        title: "15. Refunds",
        content: (
          <>
            Payments for access to the Application and for subscription period
            are non-refundable. In justified cases, if the Application will not
            work properly, after considering the justified request, we will
            provide you with additional/lost credits or extend the subscription
            period for a period corresponding to the period in which the
            Application did not work properly.
          </>
        ),
      },
      {
        title: "Contacting us",
        content: (
          <>
            If you have any questions about this Terms & Conditions or any
            complaints regarding the services please contact us -
            <span style={{ color: "#9b5de5" }}>
              &nbsp; support@aiheadshotapi.com
            </span>
            <br />
            This document was last updated on February 24, 2021
          </>
        ),
      },
    ],
  };

  return (
    <>
      <Header data={{ key: termsContent.title }} />

      <div className="container text-center section-padding">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <h1 className="heading">{termsContent.title}</h1>
            <p className="opacity-50 small-heading">
              {termsContent.description}
            </p>
          </div>
          <div className="col-12 text-start">
            <ul className="terms-li">
              {termsContent.sections.map((section, index) => (
                <li key={index}>
                  <h4>{section.title}</h4>
                  <p>{section.content}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Footer data={{ key: "Terms & Conditions" }} />
    </>
  );
};

export default TermsAndConditions;
